import React from 'react';
import { graphql } from 'gatsby';
import IndexLayout from '../../layouts/index';
import PortfolioWrapper from '../../components/portfolio/portfolioWrapper';

const Szafy = ({ data }) => {
  return (
    <IndexLayout noScroll={true}>
      <PortfolioWrapper data={data} />
    </IndexLayout>
  );
};

export const query = graphql`
  {
    allPortfolioJson(filter: { category: { eq: "Szafy" } }) {
      edges {
        node {
          id
          id_work
          name
          category
          description
          main_image {
            childImageSharp {
              gatsbyImageData(placeholder: DOMINANT_COLOR)
            }
          }
          main_image_alt
        }
      }
    }
  }
`;

export default Szafy;

export function Head() {
  return (
    <>
      <meta charSet='UTF-8' />
      <meta httpEquiv='X-UA-Compatible' content='IE=edge' />
      <meta name='viewport' content='width=device-width, initial-scale=1.0' />
      <meta
        name='description'
        content='Meble biurowe, kuchenne, szafy, zabudowy. Wszystko na wymiar. Koszalin i okolice. Ponad 25 lat doświadczenia'
      />
      <meta name='robots' content='index,follow' />
      <title>Stromich - meble na wymiar. Szafy.</title>
    </>
  );
}
